.tablet .slick-slider {
  width: 100%;
}

.tablet .container {
  padding: 40px;
}

.slick-slide img {
  margin: auto;
  width: 95%;
}

.tablet .slick-slide {
  display: flex;
  align-items: center;
}

.tablet .slick-track{
  height: 500px;
}

.tablet .slick-list {
  padding: 0 50px !important;
}

.slider-image-frame {
  max-height: 500px;
}

@media screen and (max-width: 425px)  {
  .slider-image-frame {
    max-height: 250px;
  }
  .tablet .slick-track{
    height: 182px;
  }
  .slick-slide img {
    margin: auto;
    width: 95%;
  }
}
