.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

body > div {
  -webkit-overflow-scrolling: touch;
}

html::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
}

.carousel .slider {
  display: flex;
  align-items: center;
}

.carousel .slide {
  background: none;
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.blue-aura {
  position: absolute;
  height: 15px;
  width: 15px;
  background-image: radial-gradient(circle, #042981 0%, rgba(3,36,102,0.1)  100%);
  border-radius: 50%;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.blue-star {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 5px;
  width: 5px;
  background-color: #0332D0;
  border-radius: 50%;
}

.star-container{
  position: absolute;
  animation: animStar 200s linear infinite;
}

.white-aura {
  position: absolute;
  height: 30px;
  width: 30px;
  /* background-image: radial-gradient(circle, #667795 0%, rgba(29,55,98,0.1)  100%); */
  background-image: radial-gradient(circle, rgba(102,119,149,0.7) 0%, rgba(29,55,98,0.1)  100%);
  border-radius: 50%;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.white-star {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 10px;
  width: 10px;
  background-color: #FFFFFF;
  border-radius: 50%;
}

.green-aura {
  position: absolute;
  height: 15px;
  width: 15px;
  background-image: radial-gradient(circle, rgba(63,246,204,0.1) 0%, rgba(118,153,204,0.1)  100%);
  border-radius: 50%;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.green-star {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 5px;
  width: 5px;
  background-color: #3FF6CC;
  border-radius: 50%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  background: transparent url('./assets/topBG.svg') no-repeat padding-box;
  background-position: top;
  background-size: 100%;
  opacity: 1;
  overflow: hidden;
}

.hidden {
  overflow: hidden;
}

.hidden-team {
  overflow: hidden;
  height: 1256px;
}

.multi-gradient {
  background-image: transparent linear-gradient(0deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
  margin-top: 52px;
  background-attachment: fixed; 
  width: 20px;
  height: 114px;
}

.bg-parent {
  position: relative;
  width: 100%;
}

.bg-parent-team {
  position: absolute;
  top: 5741px;
  left: 716px;
  width: calc(100% - 716px);
  height: 733px;
}

.bg {
  background: transparent url('./assets/bg.png') no-repeat padding-box;
  background-position: 51.8% 93%;
  opacity: 1;
}

@media screen and (max-width: 425px)  {
  .bg {
    background-size: 240%;
    background-position: 45% 16%;
  }
}

.bg-extra-for-mobile {
  background: none;
}

@media screen and (max-width: 425px)  {
  .bg-extra-for-mobile {
    background: transparent url('./assets/bg.png') no-repeat padding-box;
    opacity: 1;
    background-size: 240%;
    background-position: 15% 85%;
  }
}

.address-bg {
  position: absolute;
  top: 6507px;
  width: 100%;
  height: 558px;
  background: transparent linear-gradient(90deg, #000127 0%, #0028D2 13%, #0028D2 87%, #000127 100%) 0% 0% no-repeat padding-box;
  opacity: 0.1;
}

.location-img {
  position: relative;
  width: 728px;
  height: 497px;
  background: transparent url('./assets/map@2x.png') 0% 0% no-repeat padding-box;
  opacity: 1;
  background-size: cover;
  z-index: 2;
}

.menu {
  width: 100%;
  position: fixed;
  margin-top: 64px;
  /* background-color: red; */
  top: 0;
  /* z-index: -99; */
}

.home {
  height: 100%;
}

.top-light {
  width: 100%;
  height: 203px;
  background: transparent url('./assets/toplight.svg') no-repeat padding-box;
  background-position: center;
  mix-blend-mode: screen;
  opacity: 1;
}

.App-link {
  color: #61dafb;
}

.Art-work {
  position: relative;
  top: 0px;
  width: 100%;
  margin-top: 25px;
  height: 979px;
  background: transparent url('./assets/artwork.png') 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: cover;
  opacity: 1;
}

.our-service-pic {
  position: relative;
  top: 0px;
  width: 36%;
  margin-top: 38px;
  margin-right: -100px;
  height: 734px;
  background: transparent url('./assets/ourservice.svg') no-repeat padding-box;
  background-position: top -40px right 0px;
  background-size: 200%;
  opacity: 1;
  z-index: 1;
}

.design-icon {
  position: relative;
  top: 0px;
  width: 40px;
  height: 37px;
  background: transparent url('./assets/design.svg') no-repeat padding-box;
  opacity: 1;
}

.mvp-dev-icon {
  position: relative;
  top: 0px;
  width: 40px;
  height: 37px;
  background: transparent url('./assets/mvp.svg') no-repeat padding-box;
  opacity: 1;
}

.mobile-dev-icon {
  position: relative;
  top: 0px;
  width: 40px;
  height: 41px;
  background: transparent url('./assets/mobiledev.svg') no-repeat padding-box;
  opacity: 1;
}

.web-dev-icon {
  position: relative;
  top: 0px;
  width: 40px;
  height: 37px;
  background: transparent url('./assets/webdev.svg') no-repeat padding-box;
  opacity: 1;
}

.docker {
  width: 10rem;
  height: 75px;
  background: transparent url('./assets/docker.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
  mix-blend-mode: multiply;
}

.graphql {
  width: 10rem;
  height: 67px;
  background: transparent url('./assets/GraphQL.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
  mix-blend-mode: multiply;
}

.nodejs {
  width: 10rem;
  height: 60px;
  background: transparent url('./assets/node-js.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
  mix-blend-mode: multiply;
}

.react {
  width: 10rem;
  height: 92px;
  background: transparent url('./assets/React-logo.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
  mix-blend-mode: multiply;
}

.mongodb {
  width: 10rem;
  height: 35px;
  background: transparent url('./assets/MongoDB.jpeg') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
  mix-blend-mode: multiply;
}

.google-cloud {
  width: 10rem;
  height: 28px;
  background: transparent url('./assets/Google Cloud.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
  mix-blend-mode: multiply;
}

.kubernetes {
  width: 6rem;
  height: 51px;
  background: transparent url('./assets/Kubernetes_Logo.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  opacity: 1;
  mix-blend-mode: multiply;
}

.facebook {
  width: 10px;
  height: 24px;
  background: transparent url('./assets/Facebook.svg') 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: contain;
  z-index: 1;
  opacity: 1;
}

.box-suit {
  width: 100%;
  padding-top: 171%;
  background: transparent url('./assets/boxsuit.png') no-repeat padding-box;
  background-position: top;
  background-size: cover;
  transition: .2s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

/* .box-suit:hover {
  transform: scale(1.1 ,1.1);
  z-index: 2;
} */

/* .box-suit:hover {
  transform: scale(1.5 ,1.2) translate(75%, 0%);
  z-index: 2;
} */

.scg {
  width: 100%;
  padding-top: 52%;
  /* height: 576px; */
  background: transparent url('./assets/scg.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

/* .scg:hover {
  transform: scale(2) translate(0%, 30%);
  z-index: 2;
} */

.springme {
  width: 100%;
  padding-top: 63%;
  background: transparent url('./assets/springme.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

/* .springme:hover {
  transform: scale(2);
  z-index: 2;
} */

.jutiverse {
  width: 100%;
  padding-top: 65%;
  /* height: 381px; */
  background: transparent url('./assets/jutiverse.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

/* .jutiverse:hover {
  transform: scale(2) translate(-50%, 25%);
  z-index: 2;
} */

.upparts {
  width: 100%;
  padding-top: 100%;
  background: transparent url('./assets/upparts.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

.tewsbarbershop {
  width: 100%;
  padding-top: 51%;
  background: transparent url('./assets/tewsbarbershop.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

.scb {
  width: 100%;
  padding-top: 72%;
  background: transparent url('./assets/SCBWealthMockups.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

.task-interior {
  width: 100%;
  padding-top: 63%;
  background: transparent url('./assets/task-interior.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

.impact {
  width: 100%;
  padding-top: 49%;
  background: transparent url('./assets/impact.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

.metineesecret {
  width: 100%;
  padding-top: 143%;
  background: transparent url('./assets/metineesecret.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

.padoongrich {
  width: 100%;
  padding-top: 50%;
  background: transparent url('./assets/padoongrich.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

.logplus {
  width: 100%;
  padding-top: 55%;
  background: transparent url('./assets/logplus.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

.bwim {
  width: 100%;
  padding-top: 82%;
  background: transparent url('./assets/BWIM Mockup.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

.tewsmobile {
  width: 100%;
  padding-top: 82%;
  background: transparent url('./assets/TewsBarber.png') no-repeat padding-box;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  opacity: 1;
  z-index: 1;
  cursor: zoom-in;
}

@media screen and (min-width: 769px)  {
  .tablet { display: none; }
}

@media screen and (min-width: 426px)  {
  .mobile-only { display: none; }
}

@media screen and (max-width: 425px)  {
  .none-mobile { display: none; }
  .address {
    align-self: flex-start;
    padding: 0 24px;
  }
}

@media screen and (min-width: 1200px)  {
  .menu-mobile-tablet { display: none; }
  .menu-tablet-only { display: none; }
}

@media screen and (max-width: 425px)  {
  .menu-tablet-only { display: none; }
}

@media screen and (max-width: 1199px)  {
  .menu-desktop-only { display: none; }

  .docker {
    width: 6rem;
  }

  .graphql {
    width: 6rem;
  }

  .nodejs {
    width: 6rem;
  }

  .react {
    width: 6rem;
  }

  .mongodb {
    width: 6rem;
  }
  .google-cloud {
    width: 6rem;
  }
  .kubernetes {
    width: 6rem;
  }
}

@media screen and (min-width: 426px)  {
  .menu-mobile-only { display: none; }
}

@media screen and (max-width: 768px)  {
  .desktop-only { display: none; }

  .docker {
    width: 32px;
    height: 38.5px;
    margin: 0 10px;
  }
  .graphql {
    width: 60px;
    height: 34px;
    margin: 0 10px;
  }
  
  .nodejs {
    width: 60px;
    height: 30px;
    margin: 0 10px;
  }
  
  .react {
    width: 75px;
    height: 46px;
    margin: 0 10px;
  }
  
  .mongodb {
    width: 68px;
    height: 17.5px;
    margin: 0 10px;
  }
  
  .google-cloud {
    width: 91.5px;
    height: 14px;
    margin: 0 10px;
  }
  
  .kubernetes {
    width: 50.5px;
    height: 25.5px;
    margin: 0 10px;
  }
}

@font-face {
  font-family: Circular;
  src: url(./styles/fonts/CircularStd-Black.otf);
}

@font-face {
  font-family: Raleway-Regular;
  src: url(./styles/fonts/Raleway-Regular.ttf);
}

@font-face {
  font-family: Raleway-SemiBold;
  src: url(./styles/fonts/Raleway-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(./styles/fonts/Poppins-Regular.ttf)
}

@font-face {
  font-family: Raleway-Bold;
  src: url(./styles/fonts/Raleway-Bold.ttf);
}

@font-face {
  font-family: Raleway-Medium;
  src: url(./styles/fonts/Raleway-Medium.ttf);
}

@font-face {
  font-family: Raleway-ExtraBold;
  src: url(./styles/fonts/Raleway-ExtraBold.ttf);
}

@font-face {
  font-family: Raleway-Light;
  src: url(./styles/fonts/Raleway-Light.ttf);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

.carousel .slide{ background-color: none; }

.slide-container {
  width: 90%;
  /* min-height: 700px; */
  margin: auto; }

.each-slide > div {
  display: flex;
  align-items: safe center;
  justify-content: safe center;
  /* background-size: contain; */
}

.each-slide > img {
  max-width: 98%;
}

.each-slide {
  /* min-height: 1828px; */
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

@keyframes beforeBorders {
  0% {
    top: calc(1px * -1);
    left: 50%;
    bottom: auto;
    right: auto;
    width: 0;
    height: 1px;
  }
  33% {    
    top: calc(1px * -1);
    left: calc(1px * -1);
    bottom: auto;
    right: auto;
    width: calc(1px + 50%);
    height: 1px;
  }
  66% {
    top: calc(1px * -1);
    left: calc(1px * -1);
    bottom: auto;
    right: auto;
    width: 1px;
    height: calc((1px * 2) + 100%);
  }
  100% {
    top: auto;
    left: calc(1px * -1);
    bottom: calc(1px * -1);
    right: auto;
    width: calc(1px + 50%);
    height: calc((1px * 2) + 100%);
  }
}

@keyframes afterBorders {
  0% {
    top: calc(1px * -1);
    left: auto;
    bottom: auto;
    right: 50%;
    width: 0;
    height: 1px;
  }
  33% {    
    top: calc(1px * -1);
    left: auto;
    bottom: auto;
    right: calc(1px * -1);
    width: calc(1px + 50%);
    height: 1px;
  }
  66% {
    top: calc(1px * -1);
    left: auto;
    bottom: auto;
    right: calc(1px * -1);
    width: 1px;
    height: calc((1px * 2) + 100%);
  }
  100% {
    top: auto;
    left: auto;
    bottom: calc(1px * -1);
    right: calc(1px * -1);
    width: calc(1px + 50%);
    height: calc((1px * 2) + 100%);
  }
}

@keyframes borderColors {
  0% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  33% {
    border-top-color: rgba(255, 255, 255, 1);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;

  }
  66% {
    border-top-color: rgba(255, 255, 255, 1);
    border-right-color: rgba(255, 255, 255, 1);
    border-bottom-color: transparent;
    border-left-color: rgba(255, 255, 255, 1);
  }
  100% {
    border-top-color: rgba(255, 255, 255, 1);
    border-right-color: rgba(255, 255, 255, 1);
    border-bottom-color: rgba(255, 255, 255, 1);
    border-left-color: rgba(255, 255, 255, 1);
  }
}

/* .doLoud {
  cursor: url(./assets/padoongrich/mute.png),auto;
} */


.no-cursor {
  cursor: none;
}
.cursor {
  width: 20px;
  height: 20px;
  border: 10px solid rgba(var(--white-rbg-color), 0.8);
  border-radius: 50%;
  position: absolute;
  /* transition-duration: 200ms; */
  transition-timing-function: ease-out;
  animation: cursor-animate 550ms infinite alternate;
}

.cursor::after {
  background-image: url(./assets/unmute.png);
  background-size: 50px;
  content: '';
  width: 50px;
  height: 50px;
  /* border: 15px solid red; */
  border-radius: 50%;
  position: absolute;
  /* top: -25px;
  left: -25px; */
  animation: cursor-animate-2 500ms;
}

/* //mute */
.doMute:after {
  background-image: url(./assets/mute.png) !important;
}

@keyframes cursor-animate-2 {
  from {
     transform: scale(0.1);
  }

  to {
     transform: scale(1);
  }
}