body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-modal .ant-modal-content {
  background-color: RGB(2, 18, 69);
}

.ant-modal .ant-modal-close {
  color: #FFFFFF;
}

@media only screen and (max-width: 768px) {
  .precise-image {
    content: url('./assets/padoongrich/RImg-04.png');
  }
  .auto-scroll {
    animation: horizontalScroll 10s infinite linear;
  }
}


@keyframes horizontalScroll {
  100% { 
    transform: translateX(-60%);
  }
}

.effect-bottom-photo {
  height: 100%;
  background: linear-gradient(189.99deg, rgba(30, 110, 235, 0) 60.22%, rgba(30, 110, 235, 0.8) 81.74%);
  border-radius: inherit;
}

.font-size-16 {
  font-size: 16px;
  font-weight: 700px,
}